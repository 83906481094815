import React from "react";
import "./style.css";
import { useState, useEffect } from "react";

const Countdown = () => {
  const calculateTimeLeft = () => {
    let year = new Date().getFullYear();
    const difference = +new Date(`${year}-06-06`) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        DAYS: Math.floor(difference / (1000 * 60 * 60 * 24)),
        HRS: Math.floor((difference / (1000 * 60 * 60)) % 24),
        MIN: Math.floor((difference / 1000 / 60) % 60),
        SEC: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <div className="col-2">
        <span className="number">{timeLeft[interval]}</span>
        <br />
        <span className="label">{interval}</span>
      </div>
    );
  });

  return (
    <div className="row d-flex justify-content-center align-items-center no-gutters countdown">
      {/* <div className="countdown-name">{props.title}</div> */}
      {timerComponents.length ? (
        timerComponents
      ) : (
        <span className="number">Time's up!</span>
      )}
    </div>
  );
};
export default Countdown;
