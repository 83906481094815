import React from "react";
import roadmapImg from "../../Images/roadmap.png";

const Roadmap = () => {
  return (
    <section className="container-fluid" id="roadmap">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 d-flex align-items-center flex-wrap align-content-center text-left">
            <div className="svg-box">
              <img className="svg" src={roadmapImg} alt="merch" />
            </div>
            <p className="roadmap-description">
              Humans today are stuck in a toxic mix of corporate rat race,
              depression, materialism and lack of purpose. Buddhm is not a
              religion or a preaching, it is an initiative to make the moment we
              live count, without counting the moments we live. Our goal is to
              inspire humans to awaken and open their senses to experience life.
              <br />
              <br />
              The NFT art is a token to become a member of the community and a
              stakeholder in the direction this initiative takes. As the
              community get bigger, it would allow the initiative to take wings
              and spread awareness for mindful living. Our community initiative
              would support the members with inspiration, advice, goodies and
              above all a cohort of BUMs who believe in enlightenment by true
              awakening.
              <br />
              <br />
              Only those who seek will ever find, yet BUDDHM is always inside.
            </p>
          </div>
          <div className="col-0 col-lg-1"></div>
          <div className="col-lg-6 d-flex align-items-center flex-wrap align-content-center">
            <div className="container p-0">
              <div className="row roadmap-item">
                <div
                  className="col-4 roadmap-number"
                  style={{ backgroundColor: "#F0C326" }}
                >
                  0%
                </div>
                <div className="col-8 roadmap-title">
                  Giveaways and launch!!!
                </div>
              </div>
              <div className="row roadmap-item">
                <div className="col-4 roadmap-number">10%</div>
                <div className="col-8 roadmap-title">
                  NFT auctions and exclusive drops to holders
                </div>
              </div>
              <div className="row roadmap-item">
                <div
                  className="col-4 roadmap-number"
                  style={{ backgroundColor: "#F0C326" }}
                >
                  20%
                </div>
                <div className="col-8 roadmap-title">
                  Community to be created for all BUMs
                </div>
              </div>
              <div className="row roadmap-item">
                <div className="col-4 roadmap-number">50%</div>
                <div className="col-8 roadmap-title">
                  Podcast with outstanding BUMS and exclusive invite to events
                  for holders
                </div>
              </div>
              <div className="row roadmap-item">
                <div
                  className="col-4 roadmap-number"
                  style={{ backgroundColor: "#F0C326" }}
                >
                  80%
                </div>
                <div className="col-8 roadmap-title">
                  Launch a charity to help those seeking Buddhm
                </div>
              </div>
              <div className="row roadmap-item">
                <div className="col-4 roadmap-number">100%</div>
                <div className="col-8 roadmap-title">
                  Launch Buddhm coffee book and mobile app to take the
                  initiative global
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Roadmap;
