import React from "react";
import pose5 from "../../Images/pose5.png";
import pose6 from "../../Images/pose6.png";
import pose7 from "../../Images/pose7.png";
import pose8 from "../../Images/pose8.png";

const Showcase2 = () => {
  return (
    <section
      className="container-fluid showcase"
      style={{ backgroundColor: "#262022" }}
    >
      <div className="row no-gutters">
        <div className="col-3">
          <img src={pose5} alt="5" />
        </div>
        <div className="col-3">
          <img src={pose6} alt="6" />
        </div>
        <div className="col-3">
          <img src={pose7} alt="7" />
        </div>
        <div className="col-3">
          <img src={pose8} alt="8" />
        </div>
      </div>
    </section>
  );
};

export default Showcase2;
