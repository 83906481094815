import React from "react";
import faqImg from "../../Images/faq.png";

const FAQ = () => {
  return (
    <section id="FAQ" className="container-fluid">
      <div
        className="container"
        style={{
          backgroundColor: "#E74C3C",
          paddingTop: 40,
          paddingBottom: 120,
        }}
      >
        <img className="svg" src={faqImg} alt="merch" />
        <div className="accordion" id="accordion-container">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                What is Buddhm?
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
              style={{ backgroundColor: "#CB2615", marginBottom: 25 }}
            >
              <div className="accordion-body">
                <p>
                  Budhm is a movement to move humans from mindless to mindful.
                  <br />
                  <br />
                  Not a philosophy, rather a way of life for those who want to
                  live intensely, experiencing all that life has to offer. Not a
                  religion or some spiritual dogma, rather an appreciation for
                  the moments we are alive.
                  <br />
                  <br />
                  To start, an NFT art project to raise fund to build it as a
                  global initiative.
                </p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                What is the backstory?
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
              style={{ backgroundColor: "#CB2615", marginBottom: 25 }}
            >
              <div className="accordion-body">
                <p>
                  In search of purpose, I travelled the world. Seeking meaning
                  both in the tangible and intangible. From the hills to the
                  ocean, from love to hate, from poverty to riches, searching
                  what was truly mine. All I got was pondering the true meaning
                  of life.
                  <br />
                  <br />
                  Does enlightenment means to let go off all life has to offer?
                  Do we need to renounce it all and meditate under the Bodhi
                  tree? Does intense suffering open the doors to a heaven with
                  all pleasures? Or, would moksha unite us with the supreme
                  being?
                  <br />
                  <br />
                  No purpose was never real enough. All just an illusion, where
                  the rules of the game kept changing. Finally, realisation
                  dawned after many dreadful dawns. Why die for what can be
                  experienced alive. If we can imagine it, we can have it here.
                  In your skin and blood, mind and soul. Just letting of the
                  damn purpose, and being present in the moment.
                  <br />
                  <br />
                  Live like a mindful being, fully present and conscious. This
                  is message this project aims to spread.
                </p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                What can I do with my Buddhm NFT?
              </button>
            </h2>
            <div
              id="collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
              style={{ backgroundColor: "#CB2615", marginBottom: 25 }}
            >
              <div className="accordion-body">
                <p>
                  You NFT is a digital art and a membership to the community.
                  You can make this NFT part of your art collection and use it
                  for commercial project. As an exclusive membership, you get
                  many benefits such as invite to events, special merch, voting
                  right and a lot more.
                </p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingFour">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                Whats an NFT?
              </button>
            </h2>
            <div
              id="collapseFour"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
              style={{ backgroundColor: "#CB2615", marginBottom: 25 }}
            >
              <div className="accordion-body">
                <p>
                  NFT stands for "Non-fungible token," which means that it's a
                  unique, digital item with blockchain-managed ownership that
                  users can buy, own, and trade. Some NFT's fundamental function
                  is to be digital art. But they can also offer additional
                  benefits like exclusive access to websites, event tickets,
                  game items, and ownership records for physical objects. Think
                  of it as a unique piece of art that can also work as a
                  "members-only" card. Robotos works like this.
                </p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingFive">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
              >
                How do I get an NFT?
              </button>
            </h2>
            <div
              id="collapseFive"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
              style={{ backgroundColor: "#CB2615", marginBottom: 25 }}
            >
              <div className="accordion-body">
                <p>
                  New to NFTs? No worries, here are some steps on what you need
                  to do to get your Roboto.
                  <br />
                  <br />
                  Download the metamask.io extension for the Chrome/Brave
                  browser or app on mobile. This will allow you to make
                  purchases with Ethereum and can be found in the extensions
                  tab. If you are on mobile, you must use the Metamask App
                  Browser. You can purchase Ethereum through the Metamask Wallet
                  using Wyre or Send Ethereum from an exchange like Coinbase.
                  <br />
                  <br />
                  Click on Connect at the top of the page and connect your
                  Metamask. Once joined, you will be able to purchase the NFTs
                  in the mint section. You will be prompted to sign your
                  transaction. FYI, there will be a fee associated with every
                  transaction related to gas prices. Once you have made your
                  purchase, your Roboto NFTs will be viewable in your wallet and
                  on OpenSea.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
