import React from "react";
import foundingBums from "../../Images/foundingBums.png";
import teddy from "../../Images/teddy.png";
import simran from "../../Images/simran.png";
import anisha from "../../Images/anisha.png";
import piyush from "../../Images/piyush.png";

const Team = () => {
  return (
    <section className="container-fluid" id="team">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <img className="svg" src={foundingBums} alt="team" />
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-xs-12 col-lg-6">
            <a href="https://twitter.com" target="_blank" rel="noreferrer">
              <div className="row team-card">
                <div className="col-6 col-xs-12 col-lg-6">
                  <div className="img-wrapper">
                    <img
                      className="img-fluid mx-auto"
                      src={teddy}
                      alt="Team graphic"
                    />
                  </div>
                </div>
                <div className="col-6 col-xs-12 col-lg-6  flex-wrap align-items-center align-content-center justify-content-center">
                  <div className="team-wanted">BUM</div>

                  <div>
                    <h1>Teddy</h1>
                  </div>
                  <div className="team-description">Creative Director</div>
                  <div className="team-crimes">
                    {/* Wanted for clowning on Juggalos */}
                  </div>
                </div>
              </div>
            </a>
          </div>

          <div className="col-12 col-xs-12 col-lg-6">
            <a href="https://twitter.com" target="_blank" rel="noreferrer">
              <div className="row team-card">
                <div className="col-6 col-xs-12 col-lg-6">
                  <div className="img-wrapper">
                    <img
                      className="img-fluid mx-auto"
                      src={simran}
                      alt="Team graphic"
                    />
                  </div>
                </div>
                <div className="col-6 col-xs-12 col-lg-6 flex-wrap align-items-center align-content-center justify-content-center">
                  <div className="team-wanted">BUM</div>
                  <div>
                    <h1>Simran</h1>
                  </div>
                  <div className="team-description">Artist</div>
                  <div className="team-crimes">
                    {/* Wanted for Naruto running into area 51 */}
                  </div>
                </div>
              </div>
            </a>
          </div>

          <div className="col-12 col-xs-12 col-lg-6">
            <a href="https://twitter.com" target="_blank" rel="noreferrer">
              <div className="row team-card">
                <div className="col-6 col-xs-12 col-lg-6">
                  <div className="img-wrapper">
                    <img
                      className="img-fluid mx-auto"
                      src={anisha}
                      alt="Team graphic"
                    />
                  </div>
                </div>
                <div className="col-6 col-xs-12 col-lg-6  flex-wrap align-items-center align-content-center justify-content-center">
                  <div className="team-wanted">BUM</div>
                  <div>
                    <h1>Anisha</h1>
                  </div>
                  <div className="team-description">UI/UX Designer</div>
                  <div className="team-crimes">
                    {/* Wanted for sushi store robbery */}
                  </div>
                </div>
              </div>
            </a>
          </div>

          <div className="col-12 col-xs-12 col-lg-6">
            <a href="https://twitter.com" target="_blank" rel="noreferrer">
              <div className="row team-card">
                <div className="col-6 col-xs-12 col-lg-6">
                  <div className="img-wrapper">
                    <img
                      className="img-fluid mx-auto"
                      src={piyush}
                      alt="Team graphic"
                    />
                  </div>
                </div>
                <div className="col-6 col-xs-12 col-lg-6  flex-wrap align-items-center align-content-center justify-content-center">
                  <div className="team-wanted">BUM</div>
                  <div>
                    <h1>Piyush</h1>
                  </div>
                  <div className="team-description">Marketer</div>
                  <div className="team-crimes">
                    {/* Wanted for building an evil roomba army */}
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
