import React from "react";
import merchImage from "../../Images/merchImage.png";
import merchTitle from "../../Images/merch.png";

const Merch = () => {
  return (
    <section className="container-fluid" id="merch">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <img
                    className="img-fluid mx-auto pb-5"
                    src={merchImage}
                    alt="merch"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="svg-box">
              <img className="svg" src={merchTitle} alt="merch" />
            </div>
            <p>
              We aim to find and curate the finest products that are worth
              experiencing. Buddhm store is a mission and not a one-time merch
              store.
              <br />
              <br />
              Initially we would give all BUMS our latest Buddhm merch drops,
              once we reach the 80% milestone. From fresh crewneck sweaters to
              dope long sleeve tees, caps and cups, all with Buddm imagery and
              inspirational quotes.
              <br />
              <br />
              Once we reach the 100% milestone, we would start curating the
              store with unique products like chocolates and perfumes,
              hand-picked, sourced and shipped from around the world.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Merch;
