import React, { useState, useContext } from "react";
import { Web3Context } from "../../context/web3Context";
import "./style.css";

const Nav = (props) => {

  const state = useContext(Web3Context);
  return (
    <header>
      <nav
        className={`navbar navbar-expand-md fixed-top`}
        aria-label="Fourth navbar example"
      >
        <div className="container">
          <a className="navbar-brand" href="/#">
            {/* <img
              src="https://fanggang.io/images/header.png"
              className="image-fluid"
              alt="header"
            /> */}
            <h2>BUDDHM</h2>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbar-collapse"
            aria-controls="navbar-collapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
              <title>70 Basic icons by Xicons.co</title>
              <path
                d="M41,14H7a2,2,0,0,1,0-4H41A2,2,0,0,1,41,14Z"
                fill="#6f7380"
              />
              <path
                d="M41,26H7a2,2,0,0,1,0-4H41A2,2,0,0,1,41,26Z"
                fill="#6f7380"
              />
              <path
                d="M41,38H7a2,2,0,0,1,0-4H41A2,2,0,0,1,41,38Z"
                fill="#6f7380"
              />
            </svg>
          </button>

          <div className="collapse navbar-collapse" id="navbar-collapse">
            <ul className="navbar-nav mb-2 mb-md-0">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  aria-current="page"
                  href="/#about"
                >
                  About
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/#roadmap">
                  Roadmap
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/#rarity">
                  Rarity
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/#FAQ">
                  FAQ
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/#team">
                  Team
                </a>
              </li>
            </ul>

            <ul className="navbar-nav ">
              <li className="nav-item">
                {/* <a
                  className="nav-link"
                  href="https://discord.gg"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    fill="#ffffff"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 50 50"
                    width="50px"
                    height="50px"
                  >
                    <path d="M42.298,11.65c-0.676-1.021-1.633-1.802-2.768-2.256c-2.464-0.988-4.583-1.648-6.479-2.02	C31.721,7.114,30.404,7.768,29.771,9l-0.158,0.308c-1.404-0.155-2.895-0.207-4.593-0.164c-1.741-0.042-3.237,0.009-4.643,0.164	L20.22,9c-0.633-1.232-1.952-1.885-3.279-1.625c-1.896,0.371-4.016,1.031-6.479,2.02c-1.134,0.454-2.091,1.234-2.768,2.256	c-4.721,7.131-6.571,14.823-5.655,23.517c0.032,0.305,0.202,0.578,0.461,0.741c3.632,2.29,6.775,3.858,9.891,4.936	c1.303,0.455,2.748-0.054,3.517-1.229l1.371-2.101c-1.092-0.412-2.158-0.9-3.18-1.483c-0.479-0.273-0.646-0.884-0.373-1.363	c0.273-0.481,0.884-0.65,1.364-0.373c3.041,1.734,6.479,2.651,9.942,2.651s6.901-0.917,9.942-2.651	c0.479-0.277,1.09-0.108,1.364,0.373c0.273,0.479,0.106,1.09-0.373,1.363c-1.056,0.603-2.16,1.105-3.291,1.524l1.411,2.102	c0.581,0.865,1.54,1.357,2.528,1.357c0.322,0,0.647-0.053,0.963-0.161c3.125-1.079,6.274-2.649,9.914-4.944	c0.259-0.163,0.429-0.437,0.461-0.741C48.869,26.474,47.019,18.781,42.298,11.65z M18.608,28.983c-1.926,0-3.511-2.029-3.511-4.495	c0-2.466,1.585-4.495,3.511-4.495s3.511,2.029,3.511,4.495C22.119,26.954,20.534,28.983,18.608,28.983z M31.601,28.957	c-1.908,0-3.478-2.041-3.478-4.522s1.57-4.522,3.478-4.522c1.908,0,3.478,2.041,3.478,4.522S33.509,28.957,31.601,28.957z" />
                  </svg>
                </a> */}
                {state?
                 <button className="btn btn-primary" type="button" disabled>
                 {state.address.substring(0, 7)}
               </button>:  
               <button onClick={props.onConnect} type="button" className="btn btn-primary">
               Connect Wallet
             </button>
              }
                
              </li>
              {/* <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://twitter.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    fill="#ffffff"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 30 30"
                    width="60px"
                    height="60px"
                  >
                    {" "}
                    <path d="M28,6.937c-0.957,0.425-1.985,0.711-3.064,0.84c1.102-0.66,1.947-1.705,2.345-2.951c-1.03,0.611-2.172,1.055-3.388,1.295 c-0.973-1.037-2.359-1.685-3.893-1.685c-2.946,0-5.334,2.389-5.334,5.334c0,0.418,0.048,0.826,0.138,1.215 c-4.433-0.222-8.363-2.346-10.995-5.574C3.351,6.199,3.088,7.115,3.088,8.094c0,1.85,0.941,3.483,2.372,4.439 c-0.874-0.028-1.697-0.268-2.416-0.667c0,0.023,0,0.044,0,0.067c0,2.585,1.838,4.741,4.279,5.23 c-0.447,0.122-0.919,0.187-1.406,0.187c-0.343,0-0.678-0.034-1.003-0.095c0.679,2.119,2.649,3.662,4.983,3.705 c-1.825,1.431-4.125,2.284-6.625,2.284c-0.43,0-0.855-0.025-1.273-0.075c2.361,1.513,5.164,2.396,8.177,2.396 c9.812,0,15.176-8.128,15.176-15.177c0-0.231-0.005-0.461-0.015-0.69C26.38,8.945,27.285,8.006,28,6.937z" />
                  </svg>
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Nav;
