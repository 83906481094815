import React from "react";

const Charity = () => {
  return (
    <section className="container-fluid" id="charity">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 col-sm-12 col-md-8 mx-auto">
            <h1>Membership perks</h1>
            <p>
              Every BUM gets access to exclusive perks, for being a part of
              Buddhm family
            </p>
          </div>
        </div>

        <br />
        <br />
        <br />
        <br />

        <div className="row">
          <div className="col-12 col-lg-4 col-sm-12 col-md-8 mx-auto mb-4">
            <h3>IP Rights</h3>
            <br />
            <p>
              Every BUM carries the intellectual property rights to their BUM
              NFT, which means they can profit commercially from it.
            </p>
          </div>
          <div className="col-12 col-lg-4 col-sm-12 col-md-8 mx-auto mb-4">
            <h3>Exclusive Invites</h3>
            <br />
            <p>
              Your NFT is your ticket to participate in this exclusive events &
              get togethers.
            </p>
          </div>
          <div className="col-12 col-lg-4 col-sm-12 col-md-8 mx-auto mb-4">
            <h3>BUM Merch</h3>
            <br />
            <p>
              Get special goodies as a Buddhm NFT holder and discounts with
              partner brands
            </p>
          </div>
        </div>

        <br />
        <br />

        <div className="row">
          <div className="col-12 col-lg-4 col-sm-12 col-md-8 mx-auto mb-4">
            <h3>Buddhm App</h3>
            <br />
            <p>
              A community app to get inspired and also track your Buddhm state
              of mind
            </p>
          </div>
          <div className="col-12 col-lg-4 col-sm-12 col-md-8 mx-auto mb-4">
            <h3>Expert Advisory</h3>
            <br />
            <p>
              Get acess to a community of Buddhm visionaries to help you in your
              mindful journey and being one with your being
            </p>
          </div>
          <div className="col-12 col-lg-4 col-sm-12 col-md-8 mx-auto mb-4">
            <h3>And a lot More...</h3>
            <br />
            <p>
              As we search for the most amazing ways to cherish life, new
              intiaitves will be laucnhed and access given to members
            </p>
          </div>
        </div>

        <br />
      </div>
    </section>
  );
};

export default Charity;
