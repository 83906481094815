import React from "react";
import bumsWho from "../../Images/about 1.png";

const About = () => {
  return (
    <section className="container-fluid" id="about">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 col-sm-12 col-md-8 mx-auto">
            <img className="svg" src={bumsWho} alt="about" />
            <p className="about-description">
              A BUM is a mindful being, fully present in this expirement called
              life! <br />
              <br /> They seek enlightenment by experiencing rather than
              renouncing all pleasures. No abstinence and intense suffering to
              open the doors of heaven. No eternal desire for moksha to unite
              them with the supreme being. <br />
              <br /> Buddhm is not a religion but a way of life in the 21st
              century. BUM’S are a clan of beings who make the moment they live
              count, without counting the moments they live. Together this
              community inspires each other and sprinkles the magic of happiness
              on the CHUMS, beings whose mind are crumbling in the rat race.
              <br />
              <br /> Its time to Buddhm!
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
