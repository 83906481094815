import React from "react";
import rarityImg from "../../Images/rarity.png";
import rarirtyBannerImg from "../../Images/Intersect.png";
import commonImg from "../../Images/common.png";
import uncommonImg from "../../Images/uncommon.png";
import rareImg from "../../Images/rare.png";
import mythicImg from "../../Images/Mystic.png";

const Rarity = () => {
  return (
    <section className="container-fluid" id="rarity">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-lg-5">
            <div className="col-12">
              <img
                src={rarirtyBannerImg}
                className="img-fluid mx-auto rarity-special"
                alt="buddhm"
              />
            </div>
          </div>
          <div className="col-0 col-lg-1"></div>
          <div className="col-12 col-sm-12 col-lg-6">
            <div className="svg-box">
              <img className="svg" src={rarityImg} alt="merch" />
            </div>
            <p>
              Buddhm NFT is comprised of headgear, face, body, accessories and
              special powers. Each reflecting a certain purpose and promise of
              mindful enlightenment.
              <br />
              <br />
              In their journey for enlightenment, each BUM expresses themselves
              through unique ways and style. These traits common, uncommon, rare
              or mystic. Some are more intense than others, but all ways lead to
              Buddhm, true enlightenment.
              <br />
              <br />
              Some BUMs are unique and have a 1/1 editions, meaning it allows
              the BUM to stand out and shine.
            </p>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-6 col-sm-6 col-lg-3">
            <img src={commonImg} className="img-fluid mx-auto" alt="fangster" />
            <p className="rarity-name">Common</p>
          </div>
          <div className="col-6 col-sm-6 col-lg-3">
            <img
              src={uncommonImg}
              className="img-fluid mx-auto"
              alt="fangster"
            />
            <p className="rarity-name">Uncommon</p>
          </div>
          <div className="col-6 col-sm-6 col-lg-3">
            <img src={rareImg} className="img-fluid mx-auto" alt="fangster" />
            <p className="rarity-name">Rare</p>
          </div>
          <div className="col-6 col-sm-6 col-lg-3">
            <img src={mythicImg} className="img-fluid mx-auto" alt="fangster" />
            <p className="rarity-name">Mythic</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Rarity;
