import React from "react";
import pose1 from "../../Images/pose1.png";
import pose2 from "../../Images/pose2.png";
import pose3 from "../../Images/pose3.png";
import pose4 from "../../Images/pose4.png";

const Showcase1 = () => {
  return (
    <section className="container-fluid showcase">
      <div className="row no-gutters">
        <div className="col-3">
          <img src={pose1} alt="1" />
        </div>
        <div className="col-3">
          <img src={pose2} alt="2" />
        </div>
        <div className="col-3">
          <img src={pose3} alt="3" />
        </div>
        <div className="col-3">
          <img src={pose4} alt="4" />
        </div>
      </div>
    </section>
  );
};

export default Showcase1;
