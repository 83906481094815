import React, { useState, useEffect } from "react";
import "./App.css";
import Countdown from "./component/Countdown";
import Nav from "./component/Nav/Nav";
import { Web3Context } from "./context/web3Context";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import About from "./component/About";
import Roadmap from "./component/Roadmap";
import Rarity from "./component/Rarity";
import Showcase1 from "./component/Showcase1";
import Showcase2 from "./component/Showcase2";
import Charity from "./component/Charity";
import Merch from "./component/Merch";
import FAQ from "./component/FAQ";
import Team from "./component/Team";
import Footer from "./component/Footer";
import { FaInstagram } from "react-icons/fa";

import mindfullImg from "./Images/homeImage.png";

import Web3 from "web3";
import Web3Modal from "web3modal";

const App = () => {
  const [count, setCount] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [state, setState] = useState();
  async function init() {
    console.log("starting up");
    //console.log("WalletConnectProvider is", WalletConnectProvider);
    //console.log("Fortmatic is", Fortmatic);

    const providerOptions = {
      metamask: {},
    };

    const web3Modal = new Web3Modal({
      //network: "mainnet", // optional
      cacheProvider: false, // optional
      //disableInjectedProvider: false,
      providerOptions,
      theme: "light", // required
    });

    const provider = await web3Modal.connect();
    //console.log(provider);

    const web3 = new Web3(provider);
    //console.log(modal)

    const accounts = await web3.eth.getAccounts();
    const address = accounts[0];
    const networkId = await web3.eth.net.getId();
    if (networkId !== 1) {
      alert("Switch to ethereum mainnet network");
      web3Modal.clearCachedProvider();
      web3Modal.resetState();
      return;
    }
    const balance = await web3.eth.getBalance(address);

    console.log(address);

    var bal = web3.utils.fromWei(balance, "ether");
    console.log(bal);
    /**/
    //  console.log(networkId)
    setState({
      web3,
      provider,
      connected: true,
      address,
      bal,
      web3Modal,
      networkId,
    });
    // window.provider = provider;
    await subscribeProvider(provider);
  }

  const subscribeProvider = async (provider) => {
    if (!provider.on) {
      return;
    }
    // provider.on("close", () => this.resetApp());
    provider.on("accountsChanged", async (accounts) => {
      //if(state !== undefined && state.web3 !== undefined)
      //  console.log(state);
      //setProviderData({ address: accounts[0] });
      //setState({ address: accounts[0] });
      init();
    });
    provider.on("chainChanged", async (chainId) => {
      // const { web3 } = this.state;
      //const networkId = await web3.eth.net.getId();
      // setState({ chainId, networkId });
      //setProviderData({ chainId, networkId });
      init();
    });
    provider.on("networkChanged", async (networkId) => {
      //const { web3 } = this.state;
      //const chainId = await web3.eth.chainId();
      //  await this.setState({ chainId, networkId });
      //setProviderData({ chainId, networkId });
      init();
    });
    provider.on("open", () => {
      console.log("open");
    });

    // Subscribe to session disconnection/close
    provider.on("close", (code, reason) => {
      console.log(code, reason);
    });
  };

  const onConnect = async () => {
    try {
      await init();
    } catch (err) {
      console.log(err);
      alert("failed to connect");
    }
  };

  const logout = async () => {
    setTimeout(() => {
      window.location.reload();
    }, 1);
  };

  const handleChange = (e) => {
    setCount(e.target.value);
  };
  return (
    <div className="App">
      <Web3Context.Provider value={state}>
        <Nav onConnect={onConnect} />

        <section
          id="hero"
          className="container-fluid d-flex align-items-center justify-content-center"
        >
          <div className="container">
            <div className="row heroWrapper">
              <div className="col-12 col-lg-6">
                <img
                  src={mindfullImg}
                  alt="Mindfull"
                  style={{ margin: 36 }}
                  width={290}
                  height={290}
                />
                <h4 className="midTitle">Mindless to Mindful</h4>
              </div>
              <div className="col-12 col-lg-6 mint-box">
                <Countdown />
                <br />
                <span className="supply" style={{ background: "transparent" }}>
                  Minting goes live on 6th June
                </span>
                <br />
                <div className="row mint-row no-gutters">
                  {state ? (
                    <div className="col-12 col-md-12">
                      <Button
                        className="mint-btn"
                        // disabled={true}
                        variant="primary"
                        size="lg"
                      >
                        MINT!
                      </Button>

                      {/* <Dropdown>
                    <Dropdown.Toggle id="mint-amount">{count}</Dropdown.Toggle>
                    <Dropdown.Menu id="mint-amount">2</Dropdown.Menu>
                  </Dropdown> */}

                      <select
                        id="mint-amount"
                        value={count}
                        onChange={handleChange}
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                      </select>
                    </div>
                  ) : (
                    <div className="col-12 col-md-12">
                      <Button
                        onClick={onConnect}
                        className="mint-btn"
                        // disabled={true}
                        variant="primary"
                        size="lg"
                      >
                        Connect Wallet
                      </Button>
                    </div>
                  )}
                </div>

                <div className="row">
                  {/* <div className="col-lg-12 hero-minted">
                    0.04 ETH <span className="supply">0 left!</span>
                    max 10
                  </div> */}

                  <div className="d-flex justify-content-center align-items-center mt-3">
                    <div className="fsocials">
                      <a
                        href="https://discord.gg"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <svg
                          fill="#ffffff"
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          viewBox="0 0 50 50"
                          width="50px"
                          height="50px"
                        >
                          <path d="M42.298,11.65c-0.676-1.021-1.633-1.802-2.768-2.256c-2.464-0.988-4.583-1.648-6.479-2.02	C31.721,7.114,30.404,7.768,29.771,9l-0.158,0.308c-1.404-0.155-2.895-0.207-4.593-0.164c-1.741-0.042-3.237,0.009-4.643,0.164	L20.22,9c-0.633-1.232-1.952-1.885-3.279-1.625c-1.896,0.371-4.016,1.031-6.479,2.02c-1.134,0.454-2.091,1.234-2.768,2.256	c-4.721,7.131-6.571,14.823-5.655,23.517c0.032,0.305,0.202,0.578,0.461,0.741c3.632,2.29,6.775,3.858,9.891,4.936	c1.303,0.455,2.748-0.054,3.517-1.229l1.371-2.101c-1.092-0.412-2.158-0.9-3.18-1.483c-0.479-0.273-0.646-0.884-0.373-1.363	c0.273-0.481,0.884-0.65,1.364-0.373c3.041,1.734,6.479,2.651,9.942,2.651s6.901-0.917,9.942-2.651	c0.479-0.277,1.09-0.108,1.364,0.373c0.273,0.479,0.106,1.09-0.373,1.363c-1.056,0.603-2.16,1.105-3.291,1.524l1.411,2.102	c0.581,0.865,1.54,1.357,2.528,1.357c0.322,0,0.647-0.053,0.963-0.161c3.125-1.079,6.274-2.649,9.914-4.944	c0.259-0.163,0.429-0.437,0.461-0.741C48.869,26.474,47.019,18.781,42.298,11.65z M18.608,28.983c-1.926,0-3.511-2.029-3.511-4.495	c0-2.466,1.585-4.495,3.511-4.495s3.511,2.029,3.511,4.495C22.119,26.954,20.534,28.983,18.608,28.983z M31.601,28.957	c-1.908,0-3.478-2.041-3.478-4.522s1.57-4.522,3.478-4.522c1.908,0,3.478,2.041,3.478,4.522S33.509,28.957,31.601,28.957z" />
                        </svg>
                      </a>
                    </div>

                    <div className="fsocials">
                      <a
                        href="https://twitter.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <svg
                          fill="#ffffff"
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          viewBox="0 0 30 30"
                          width="60px"
                          height="60px"
                        >
                          {" "}
                          <path d="M28,6.937c-0.957,0.425-1.985,0.711-3.064,0.84c1.102-0.66,1.947-1.705,2.345-2.951c-1.03,0.611-2.172,1.055-3.388,1.295 c-0.973-1.037-2.359-1.685-3.893-1.685c-2.946,0-5.334,2.389-5.334,5.334c0,0.418,0.048,0.826,0.138,1.215 c-4.433-0.222-8.363-2.346-10.995-5.574C3.351,6.199,3.088,7.115,3.088,8.094c0,1.85,0.941,3.483,2.372,4.439 c-0.874-0.028-1.697-0.268-2.416-0.667c0,0.023,0,0.044,0,0.067c0,2.585,1.838,4.741,4.279,5.23 c-0.447,0.122-0.919,0.187-1.406,0.187c-0.343,0-0.678-0.034-1.003-0.095c0.679,2.119,2.649,3.662,4.983,3.705 c-1.825,1.431-4.125,2.284-6.625,2.284c-0.43,0-0.855-0.025-1.273-0.075c2.361,1.513,5.164,2.396,8.177,2.396 c9.812,0,15.176-8.128,15.176-15.177c0-0.231-0.005-0.461-0.015-0.69C26.38,8.945,27.285,8.006,28,6.937z" />
                        </svg>
                      </a>
                    </div>

                    <div className="fsocials">
                      <a
                        href="https://www.instagram.com/buddhm_/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FaInstagram />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Showcase1 />
        <About />
        <Showcase2 />
        <Roadmap />
        <Rarity />
        <Charity />
        <Merch />
        <FAQ />
        <Team />
        <Footer />
      </Web3Context.Provider>
    </div>
  );
};

export default App;
